import React from "react";
import './Header.css';
import logo from '../../images/logo1.png';
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Header = () => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header>
        <Container fluid className="py-1 bg-white">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="logo">
              <Navbar.Brand as={Link} to="/">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Navbar.Brand>
            </div>

            <div className="d-flex flex-column flex-grow-1">
              <div className="top-nav d-none d-lg-flex justify-content-between align-items-center">
                <div className="contact-info d-flex align-items-center">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  <span className="me-4">+91-9561416617</span>
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <span>contact@onetouchskinclinic.com</span>
                  <span className="ms-4">| Book Appointment</span>
                </div>
                <div className="social-icons d-flex align-items-center">
                  <a href="https://www.facebook.com" className="me-3" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="1x" />
                  </a>
                  <a href="https://www.twitter.com" className="me-3" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} size="1x" />
                  </a>
                  <a href="https://www.instagram.com" className="me-3" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                  </a>
                  <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size="1x" />
                  </a>
                </div>
              </div>

              <Navbar expand="lg" className="p-0 mt-2">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleShow} />
                <Navbar.Collapse id="responsive-navbar-nav" className="d-none d-lg-flex">
                  <Nav className="ms-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About Us</Nav.Link>
                    <Nav.Link as={Link} to="">Services</Nav.Link>
                    <Nav.Link as={Link} to="">Clients' Speak</Nav.Link>
                    {/* <Nav.Link as={Link} to="">Career</Nav.Link> */}
                    <Nav.Link as={Link} to="">Appointments</Nav.Link>
                    <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </Container>
      </header>

      <Offcanvas show={show} onHide={handleClose} className="offcanvas-nav">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ color: 'white' }}>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to="/" onClick={handleClose}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={handleClose}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/services" onClick={handleClose}>Services</Nav.Link>
            <Nav.Link as={Link} to="/clients" onClick={handleClose}>Clients' Speak</Nav.Link>
            <Nav.Link as={Link} to="/career" onClick={handleClose}>Career</Nav.Link>
            <Nav.Link as={Link} to="/appointments" onClick={handleClose}>Appointments</Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleClose}>Contact</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
