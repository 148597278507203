import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Laser1 from '../../images/laser1.webp';
import Laser2 from '../../images/laser2.webp'
import Laser3 from '../../images/laser3.webp'

import './Doctor.css'
const Doctor = () => {
  return (
    <div className="container doctor-container my-5" style={{ marginBottom: '50px' }}>
      <h1 style={{ color: 'black' }}>Dr. Renuka Kore & Dr. Mayuri Jaiswal</h1>
      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-6">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Laser1}
                alt="Doctor 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Laser2}
                alt="Doctor 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Laser3}
                alt="Doctor 3"
              />
            </Carousel.Item>

          </Carousel>
        </div>

        {/* Right side: Doctor Information */}
        <div className="col-md-6 d-flex align-items-center">
          <div className="doctor-info">
            <p style={{ color: 'black',fontSize:'24px' }}>

              Dr. Renuka and Dr. Mayuri are highly qualified homeopathic practitioners with BHMS degrees. They have completed postgraduate diplomas in Clinical Cosmetology (PGDCC) and Medical Trichology (PGDMT), equipping them with specialized skills in hair and skin treatments. As laser experts, they offer advanced aesthetic procedures, ensuring personalized care and effective results for their patients in a professional and welcoming environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doctor;
