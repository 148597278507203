import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission logic here
    console.log("Form submitted:", formData);
    alert("Thank you for contacting us!");
  };

  return (
    <Container className="contact-container">
      <h2 className="text-center contact-heading mb-4">Get in Touch</h2>
      <Row>
        <Col md={6} className="contact-form-container">
          <h4>Contact Form</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Write your message here"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Send Message
            </Button>
          </Form>
        </Col>

        <Col md={6} className="contact-info-container">
          <h4>Contact </h4>
          <div className="info-box">
            <h5>Address</h5>
            <p>
              Shop no 113, VTP Complex,<br />
              Undri, Pune, Maharashtra 411028
            </p>
          </div>

          <div className="info-box">
            <h5>Phone</h5>
            <p>+91 9561416617</p>
          </div>

          <div className="info-box">
            <h5>Email</h5>
            <p>contact@onetouchskinclinic.com</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
