import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import './SecondSlide.css';
import Onetouch4 from '../../images/onetouch4.jpg';
import Onetouch5 from '../../images/onetouch5.jpg';
import Onetouch6 from '../../images/onetouch6.jpg';
import Onetouch7 from '../../images/onetouch7.webp';

const SecondSlide = () => {
  const slides = [
    {
      imgSrc: Onetouch6
    },
    {
      imgSrc: Onetouch4
    },
    {
      imgSrc: Onetouch5
    },
    
    {
      imgSrc: Onetouch7
    },
  ];

  const title = "#OneTouchSkinClinic";
  const description = `"We are skin expertise in skin care service for male and females #OneTouchSkinClinic"`;

  return (
    <Container fluid className="second-carousel-container">
      <Row className="align-items-center">
        {/* Carousel */}
        <Col md={8} xs={12}>
          <div className="secondcarousel-wrapper">
            <Carousel
              nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
              prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
              indicators={false}
            >
              {/* Images in the carousel */}
              {slides.map((slide, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 secondcustom-carousel-img"
                    src={slide.imgSrc}
                    alt={`Slide ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Col>

        {/* Title and description */}
        <Col md={4} xs={12} className="text-left second-carousel-text">
          <h2 className="secondcarousel-title">{title}</h2>
          <p className="secondcarousel-description">{description}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default SecondSlide;
