import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './VideoCarousel.css'; // Import custom styles if needed
import { Container} from "react-bootstrap";

const VideoCarousel = () => {
  const videos = [
    { id: 1, videoUrl: 'https://www.cutis.in/images/youtube3.webp' },
    { id: 2, videoUrl: 'https://www.cutis.in/images/youtube4.webp' },
    { id: 3, videoUrl: 'https://www.cutis.in/images/youtube5.webp' },
    { id: 4, videoUrl: 'https://www.cutis.in/images/youtube1.webp' },
    { id: 5, videoUrl: 'https://www.cutis.in/images/youtube2.webp' },
    { id: 6, videoUrl: 'https://www.cutis.in/images/youtube6.webp' },
    { id: 7, videoUrl: 'https://www.cutis.in/images/youtube7.webp' },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4, // Show 4 videos at a time for large screens
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3, // Show 3 videos for medium screens
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2, // Show 2 videos for tablet screens
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show 1 video for mobile screens
    },
  };

  return (
    <Container fluid className="video-carousel-container">

    <div>
      <Carousel responsive={responsive} infinite={true} autoPlay={false}>
        {videos.map((video) => (
          <div key={video.id} className="video-item">
            <video controls width="100%">
              <source src={video.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3>{video.title}</h3>
          </div>
        ))}
      </Carousel>

      {/* View All Videos Button */}
      <div className="view-all-videos">
        <button className="view-all-button">View All Videos ></button>
      </div>
    </div>
    </Container>
  );
};

export default VideoCarousel;
