import React from 'react'

const Client = () => {
  return (
    <div>
      Clients' speaks
    </div>
  )
}

export default Client
