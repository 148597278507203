import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './Cutis.css'; // Import CSS for styling

const Cutis = () => {
    return (
        <Container fluid className="cutis-container">
            <h1>Why One Touch Skin Clinic?</h1>
            <Row className="cutis-row">
                {/* Left side text */}
                <Col md={6} className="text-column">
                    <div className="text-box">
                        <h2>Expertise and Experience</h2>
                        <p className="image-description">
                            One Touch Skin Clinic is staffed by highly trained professionals with extensive experience in dermatology and cosmetic treatments. Our team is dedicated to providing the highest standard of Clinic, ensuring each patient receives personalized attention tailored to their unique skin needs.
                        </p>
                    </div>
                    <div className="text-box">
                        <h2>Advanced Technology</h2>
                        <p className="image-description">
                            We utilize state-of-the-art technology and cutting-edge techniques to deliver effective treatments. From laser therapies to advanced skincare products, our clinic is equipped with the latest innovations, ensuring optimal results while prioritizing patient safety and comfort.
                        </p>
                    </div>
                </Col>

                {/* Right side text */}
                <Col md={6} className="text-column">
                    <div className="text-box">
                        <h2>Comprehensive Services</h2>
                        <p className="image-description">
                            Our clinic offers a wide range of services, from basic skincare to advanced dermatological treatments. Whether you're seeking solutions for acne, pigmentation, anti-aging, or rejuvenation, we provide holistic care that addresses all aspects of skin health and beauty.
                        </p>
                    </div>
                    <div className="text-box">
                        <h2>Patient-Centered Approach</h2>
                        <p className="image-description">
                            At One Touch Skin Clinic, patient satisfaction is our top priority. We foster a welcoming and supportive environment, encouraging open communication. Our team takes the time to understand your concerns and goals, ensuring a collaborative approach to your skincare journey.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Cutis;
