import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './VideoSlide.css'; // Import the updated CSS file
import Onetouch2 from '../../images/onetouch5.jpg';

const VideoSlide = () => {
  return (
    <Container fluid className="p-5 video-slide-container">
      <h2 className="video-title">Welcome to One Touch Skin Clinic</h2>

      <Row className="video-slide-row">
        {/* Image on the left side */}
        <Col md={6} className="p-0 image-column">
          <div className="image-wrapper">
            <img
              src={Onetouch2}
              alt="One Touch Skin Care"
              className="custom-image"
            />
          </div>
        </Col>

        {/* Description on the right side */}
        <Col md={6} className="text-left video-description">
          <p className="video-text">
          One Touch Skin Clinic is a leading dermatology clinic specializing in advanced skin care treatments and cosmetic procedures. We offer personalized solutions for a variety of skin concerns, including acne, pigmentation, anti-aging, and hair loss. Our services include laser treatments, chemical peels, microdermabrasion, PRP therapy, and customized facials, all performed by highly trained dermatologists using state-of-the-art technology. Whether you're looking for medical skin care or aesthetic enhancements, we are dedicated to helping you achieve healthy, glowing skin.          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoSlide;
