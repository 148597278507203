import React from 'react';
import FirstSlide from './InnerComponent/FirstSlide';
import SecondSlide from './InnerComponent/SecondSlide';
import VideoSlide from './InnerComponent/VideoSlide';
import TreatmentPage from './InnerComponent/TreatmentPage';
import Cutis from './InnerComponent/Cutis';
import Doctor from './InnerComponent/Doctor';
import Gallary from './InnerComponent/Gallary';
import Clients from './InnerComponent/Clients';
import Review from './InnerComponent/Review';
import VideoCarousel from './InnerComponent/VideoCarousel';
import Address from './InnerComponent/Address';
const Home = () => {
  return (
    <div className="main-content">
      <FirstSlide />
      <SecondSlide />
      <VideoSlide />
      <TreatmentPage />
      <Cutis />
      <Doctor />
      {/* <Gallary /> */}
      {/* <Clients /> */}
      {/* <Review /> */}
      {/* <VideoCarousel /> */}
      {/* <Address /> */}
    </div>
  );
};

export default Home;
