import React from "react";
import Slider from "react-slick";
import './TreatmentPage.css'; // Custom CSS for the treatment page
import Treat1 from '../../images/treat1.webp';
import Treat2 from '../../images/treat2.webp';
import Treat3 from '../../images/treat3.webp';
import Treat4 from '../../images/treat4.webp';
import Treat5 from '../../images/treat5.webp';

const TreatmentPage = () => {
  // Array of images
  const images = [
    { src: Treat1 },
    { src: Treat2 },
    { src: Treat3 },
    { src: Treat4 },
    { src: Treat5 },
    { src: "https://www.cutis.in/images/resource/pigmentaton-treatment.webp" },
    { src: "https://www.cutis.in/images/resource/antiageing-treatment.webp" },
    { src: "https://www.cutis.in/images/resource/medicleanup.webp" },
    { src: "https://www.cutis.in/images/resource/cryolipolysis.webp" },
    { src: "https://www.cutis.in/images/resource/laser-hair-removal.webp" },
    { src: "https://www.cutis.in/images/resource/dermato-surgery.webp" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default: Shows 4 images at a time
    slidesToScroll: 1, // Scrolls 1 image at a time
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hides the navigation arrows
    customPaging: () => (
      <div className="custom-dot"></div> // Custom dot indicator
    ),
    responsive: [
      {
        breakpoint: 768, // When the screen width is 768px or less
        settings: {
          slidesToShow: 2, // Shows 2 images at a time
        },
      },
    ],
  };

  return (
    <div className="treatment-page">
      <h1 className="text-center mb-5"> Treatments</h1>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide-item">
            <img src={image.src} alt={`Treatment ${index + 1}`} className="treatment-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TreatmentPage;
