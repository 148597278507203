import React from 'react'

const Appointments = () => {
  return (
    <div>
      Appointments
    </div>
  )
}

export default Appointments
