import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import './About.css';
const About = () => {
  return (
    <div>
      <Container fluid className="p-5 about-container">
      <h2 className="about-title">About Us</h2>

      <Row className="about-row">
                {/* Description on the right side */}
        <Col md={6} className="text-centre about-description">
          <p className="about-text text-centre">
          One Touch Skin Clinic is a leading dermatology clinic specializing in advanced skin care treatments and cosmetic procedures. We offer personalized solutions for a variety of skin concerns, including acne, pigmentation, anti-aging, and hair loss. Our services include laser treatments, chemical peels, microdermabrasion, PRP therapy, and customized facials, all performed by highly trained dermatologists using state-of-the-art technology. Whether you're looking for medical skin care or aesthetic enhancements, we are dedicated to helping you achieve healthy, glowing skin.          </p>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default About
