import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section about-section">

        <h3>For Appointments:</h3>
        <p>
          <i className="fas fa-phone"></i> 9561416617
        </p>
        <p>
          <i className="fas fa-envelope"></i> contact@onetouchskinclinic.com
        </p>

      </div>

      <div className="footer-section social-section">
        <h3>Follow Us</h3>
        <ul className="social-links">
          <li><a href="https://facebook.com"><i className="fab fa-facebook-f"></i></a></li>
          <li><a href="https://twitter.com"><i className="fab fa-twitter"></i></a></li>
          <li><a href="https://instagram.com"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://linkedin.com"><i className="fab fa-linkedin"></i></a></li>
        </ul>
      </div>
      <div >
        <div className="footeraddress-box">
          <h3>Our Address</h3>
          <p>Shop no 113, VTP Complex, Undri, Pune,<br/> Maharashtra 411028</p>

        </div>
      </div>

      <div className="footer-section map-section">
        {/* <h3>Find Us</h3> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.01835596504!2d73.9126824!3d18.4494485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4e8eccb8b5bd4027%3A0x43eab4643d14bd8b!2sOne%20Touch%20Skin%20Clinic!5e0!3m2!1sen!2sin!4v1727006662701!5m2!1sen!2sin"
          width="60%"  // Adjust to 100% for full-width
          height="100px"  // Set height to your preference
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
    </footer>
  );
};

export default Footer;
