import React, { useState } from "react";
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import './FirstSlide.css';
import Onetouch1 from '../../images/onetouch1.jpg';
import Onetouch2 from '../../images/onetouch2.jpg';
import Onetouch3 from '../../images/onetouch3.jpg';
import Onetouch4 from '../../images/onetouch4.jpg';
import Onetouch5 from '../../images/onetouch5.jpg';

const FirstSlide = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const titles = [
    "Our Team - Excellence in Care",
    "Innovative IV Infusion Treatments",
    "Trusted Procedures",
    "State-of-the-Art Technology",
    "Modern Clinic Facilities",
    "Transformational Stories"
  ];

  const buttons = [
    { label: "Know More >", link: "#team" },
    { label: "Know More >", link: "#iv-infusion" },
    { label: "Book An Assessment Now >", link: "#celebrities" },
    { label: "Book An Assessment Now >", link: "#awards" },
    { label: "Book An Assessment Now >", link: "#technology" },
    { label: "Book An Assessment Now >", link: "#clinic" },
    { label: "Book An Assessment Now >", link: "#transformations" }
  ];

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Container fluid className="firstSlid-container p-5" >
      <Row className="align-items-center">
        <Col md={4} className="text-center">
          <h1 className="firstcarousel-title">{titles[activeIndex]}</h1>
          <Button 
            variant="primary" 
            href={buttons[activeIndex].link} 
            className="firstcustom-button"
            style={{backgroundColor:'#120320',paddingTop:'7px',fontWeight:'bold',borderRadius:'30px'}}
          >
            {buttons[activeIndex].label}
          </Button>
        </Col>

        <Col md={8}>
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
            prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
            indicators={false}
          >
             <Carousel.Item>
              <img
                className="d-block w-100 firstCustom-carousel-img"
                src={Onetouch4}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 firstCustom-carousel-img"
                src={Onetouch5}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 firstCustom-carousel-img"
                src={Onetouch1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 firstCustom-carousel-img"
                src={Onetouch2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 firstCustom-carousel-img"
                src={Onetouch3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default FirstSlide;
